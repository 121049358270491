<template>
<!--<div>-->
<!--  <img src="../assets/logo_nadp.png" width="277" >-->
<!--</div>-->

<nav class="navbar navbar-expand-lg navbar-light bg-light rounded-2" >
  <div class="container-fluid">

    <a class="navbar-brand me-5" href="#">
      <img src="../assets/hp_logo_nadp.png" width="277" >
    </a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-1 mb-lg-0 w-100 justify-content-between">

        <li class="nav-item" >
          <a href="#about" class="nav-link mx-2 text-dark fw-bold">О сервисе</a>
        </li>

        <li class="nav-item">
          <a href="#opportunities" class="nav-link mx-2 text-dark fw-bold">Возможности</a>
        </li>

        <li class="nav-item">
          <a href="#start" class="nav-link mx-2 text-dark fw-bold">Начало работы</a>
        </li>

        <li class="nav-item">
          <a href="#pricing" class="nav-link mx-2 text-dark fw-bold">Тарифы</a>
        </li>

<!--        <li class="nav-item">-->
<!--          <a href="#reviews" class="nav-link mx-2 text-dark fw-bold">Отзывы</a>-->
<!--        </li>-->

        <li class="nav-item">
          <router-link :to="{ name: 'UserEnter'}" class="nav-link text-white bg-success rounded-2 mx-4 fw-bold">Войти</router-link>
        </li>

      </ul>
    </div>

  </div>
</nav>


  <div class="row">
    <div class="col-lg-6">

      <div class="row">
        <div class="col-12">
          <h1>Рост ваших продаж до <span class="text-success">х3</span> на Вайлдберис с сервисом МП Бубен</h1>
        </div>
      </div>

      <div class="row lg-3">
        <div class="col-sm-6">
          <a target="_blank" href="https://t.me/mpbuben_bot?start=register" class="btn btn-success mt-2 p-4 fw-bold w-100">Зарегистрироваться сейчас</a>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-1 mt-2">
              <img src="../assets/check_1.svg" >
            </div>

            <div class="col-auto mt-2">
              <p>
                Рентабельность товаров <br/>
                Рассчет прибыли <br/>
                Остатки по складам <br/>
                Контроль ставок рекламы
              </p>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="col-6">
      <img src="../assets/hp_screenshot.png" width="519" >
    </div>

  </div>

  <div class="row mt-5">
    <div class="col-12">
      <div class="border rounded-4 p-3">
        <div class="row">

          <div class="col-lg-8">
            <div class="row">
              <div class="col-12">
                <h1 id="about"><span class="text-success">МП Бубен</span> позволяет отслеживать все ключевые показатели:</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-1">
                    <img src="../assets/check_1.svg" >
                  </div>
                  <div class="col-auto">
                    <p>
                      Продажи<br/>
                      Возвраты<br/>
                      Рентабельность вложений<br/>
                      Норма прибыли<br/>
                      Комиссия WB<br/>
                      Себестоимость
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6">
                 <div class="row">
                    <div class="col-1">
                      <img src="../assets/check_1.svg" >
                    </div>
                    <div class="col-auto">
                      <p>
                        Графики заказов по складам<br/>
                        Остатки по складам<br/>
                        Заказы по складам<br/>
                        Текущие ставки рекламы<br/>
                        Штрафы<br/>
                        Прочие удержания
                      </p>
                    </div>
                 </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>
                  Раздел «Недельные отчеты» на Wildberries позволяет получить базовую статистику для ведения бизнеса на маркетплейсе.
                  Но, для принятия важных решений предпринимателю необходимо понимать ситуацию и контролировать еженедельные отчеты.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="border rounded-4 p-3 bg-success text-white">
              <p>
                Увеличь свою прибыль с МП Бубен уже сегодня
              </p>
              <p>
                <ul>
                  <li>
                    Создай систему управления своими продажами и увеличь прибыль
                  </li>
                  <li>
                    Автоматизируй анализ еженедельных отчетов PL и CF, статистику с загрузкой по API Wildberries
                  </li>
                  <li>
                    Освободи свое время для работы над более приоритетными задачами
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <h1>Нашим сервисом <br/><span class="text-success">активно</span> пользуются</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="border rounded-4 p-3 bg-light mt-3">
        <img src="../assets/check_1.svg" width="30" >
        <h2>Селлеры Вайлдберриз</h2>
        <p>
          Получайте всю информацию о товаре на одной странице, автоматизируйте подготовку управленческой отчетности.
          Делегируйте рутинные задачи сервису и сфокусируйтесь на производстве и поставках максимально прибыльных товаров.
        </p>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="border rounded-4 p-3 bg-light mt-3">
        <img src="../assets/check_1.svg" width="30" >
        <h2>Менеджеры Вайлдберриз</h2>
        <p>
          Полная автоматизация сбора и подготовки финансовой отчетности для своих заказчиков.
          Освободите время для работы с новыми клиентами и зарабатывайте в разы больше.
        </p>

      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <h1 id="opportunities">Возможности <span class="text-success">МП Бубен</span>:</h1>
      <p>
        Сервис включает в себя 4 отчета для удобного анализа показателей доходности.
        Анализируйте сильные и слабые стороны и увеличивайте эффективность и продажи.
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-4 mt-5">
      <img src="../assets/hp_week.png" width="100%" >
      <h3 class="mt-2">Раздел Недельные отчеты</h3>
      <p>Сколько денег вы заработали, какая рентабельность, норма прибыли и еще более 30 показателей</p>
    </div>

    <div class="col-lg-4 mt-5">
      <img src="../assets/hp_dashboard.png" width="100%" >
      <h3 class="mt-2">Раздел Дашбоард</h3>
      <p>Удобно смотреть ежедневные заказы</p>
    </div>

    <div class="col-lg-4 mt-5">
      <img src="../assets/hp_products.png" width="100%" >
      <h3 class="mt-2">Раздел Мои товары</h3>
      <p>Список ваших товаров (баркодов) + остатки в разбивке по складам + заказы по дням</p>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <div class="border rounded-4 p-3 bg-light">
        <div class="row mt-5">
          <div class="col-lg-8">
            <h1 mt-5 mb-4 id="start">Начать работу в <br/> сервисе <span class="text-success">МП Бубен</span>:</h1>
          </div>

          <div class="col-lg-4">
            <div class="row">
                <div class="col-auto">
                  <a href="https://t.me/mpbuben_bot?start=register" target="_blank">
                    <img src="../assets/hp_tg.png" width="73" >
                  </a>
                </div>
                <div class="col-auto">
                  <a href="https://t.me/mpbuben_bot?start=register" target="_blank" class="text-dark">
                    <h3>Телеграм-бот</h3>
                  </a>
                  <p>Начни регистрацию прямо сейчас</p>
                </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="border rounded-4 p-3 bg-white mt-3">
              <span class="badge bg-success">1</span>
              <br>
              <h3 class="mt-3">Регистрация</h3>
              Зарегистрируйтесь в сервисе через телеграм бота за 30 секунд
              <a href="https://t.me/mpbuben_bot?start=register" target="_blank" class="btn btn-success mt-2 p-4 fw-bold w-100">Зарегистрироваться сейчас</a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="border rounded-4 p-3 bg-white mt-3">
              <span class="badge bg-success">2</span>
              <br>
              <h3 class="mt-3">Добавление селлера</h3>
              Получите API - ключ статистики в кабинете селлера Валберис и затем добавьте его в нашу систему. После загрузки данных вы получите уведомление.
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="border rounded-4 p-3 bg-white mt-3">
              <span class="badge bg-success">3</span>
              <br>
              <h3 class="mt-3">Бесплатный период</h3>
              Пользуйтесь всеми отчетами сервиса в течении 3 месяцев, увеличивайте свою прибыль, автоматизирйте свои процессы и рекомендуйте нас своим друзьям.
            </div>
          </div>
          <div class="col-lg-6">
            <div class="border rounded-4 p-3 bg-white mt-3">
              <span class="badge bg-success">4</span>
              <br>
              <h3 class="mt-3">Выбор тарифа</h3>
              Оценив полезность нашего сервиса и рост дохода вашего бизнеса - вы сможете выбрать удобные для вас условия работы в нашем сервисе и оплатить.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-8">
      <h1 id="pricing">Тарифы <span class="text-success">МП Бубен</span>:</h1>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-lg-8">
      <p>
        Первым 50 пользователям дарим абсолютно бесплатный тариф с возможностью использовать весь функционал и отчеты для работы с маркетплейсами.
      </p>
      <p>
        Мы будем признательны, если поделитесь своими предложениями об улучшении функционала нашего сервиса.
      </p>
    </div>

    <div class="col-lg-4">
      <div class="row">
        <div class="col-12">
          <div class="border rounded-4 p-2 bg-light text-dark text-center">Первым 50 пользователям бесплатно</div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="border rounded-4 p-2 mt-1 bg-light text-dark text-center">Регистрируйся сейчас</div>
        </div>
        <div class="col-6">
          <div class="border rounded-4 p-2 mt-1 bg-light text-center">Пиши на <a class="text-dark" target="_blank" :href="'https://t.me/' + LOC_TG_SUPPORT_NAME">@{{LOC_TG_SUPPORT_NAME}}</a></div>
        </div>
      </div>
    </div>
  </div>

<!--  <div class="row mt-5">-->
<!--    <div class="col-lg-8">-->
<!--      <h1 id="reviews">Посмотрите, что говорят пользователи о нашем сервисе <span class="text-success">МП Бубен</span>:</h1>-->
<!--    </div>-->
<!--  </div>-->

  <div class="row mt-5">
    <div class="col-lg-4">
      <h1>Контакты</h1>
      <a target="_blank" :href="'https://t.me/' + LOC_TG_SUPPORT_NAME"><img class="m-1" src="../assets/hp2_tg.svg" width="62"></a>
      <a target="_blank" href="https://wa.me/79098221940"><img class="m-1" src="../assets/hp2_wa.svg" width="62"></a>
      <br/>
      <h3 class="mt-1">+7 909 822 19 40</h3>
      <p>Мы на связи каждый день с 10:00 до 20:00</p>
      <h3 class="mt-1 text-secondary">info@mpbuben.ru</h3>
      <a href="https://t.me/mpbuben_bot?start=register" target="_blank" class="btn btn-success mt-4 p-4 fw-bold w-100">Зарегистрироваться сейчас</a>
    </div>
    <div class="col-lg-8">
      <img class="m-2" src="../assets/hp_products2.png" width="100%">
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-lg-6">
      <img class="m-2" src="../assets/hp_logo_nadp.png" width="200">
      <div class="badge bg-white text-dark align-bottom">Аналитика маркетплейсов</div>
    </div>
    <div class="col-6 text-end align-middle">
      <!-- a href="#" class="text-dark">Политика конфиденциальности</a-->
    </div>
  </div>

</template>

<script>
import {TG_SUPPORT_NAME} from "@/common";

export default {
  name: "PageHome",

  data() {
    return {
      username: "",
      password: "",
      messageResult: "",
      gotError: false,
      LOC_TG_SUPPORT_NAME: ""
    }
  },

  created() {
    this.LOC_TG_SUPPORT_NAME = TG_SUPPORT_NAME;
  },

}
</script>

<style scoped>

</style>

<!--    <h1>Heroes of Marketing and Marketplaces</h1>-->
