<template >
  <h1>ОЗОН товары</h1>

  <div v-if="messageResult" class="alert " :class="{ 'alert-danger': gotError, 'alert-success': !gotError }"
       role="alert">
    {{ messageResult }}
  </div>

  <div class="row">
    <div class="col-sm-auto mt-2">
      <label for="inputDateStart" class="col-form-label">Заказы с</label>
    </div>

    <div class="col-sm-auto mt-2">
      <input v-model="dateStart" type="date" id="inputDateStart" class="form-control" @change="changeOrdersDates">
    </div>

    <div class="col-sm-auto mt-2">
      <label for="inputDateEnd" class="col-form-label">по</label>
    </div>

    <div class="col-sm-auto mt-2">
      <input v-model="dateEnd" type="date" id="inputDateEnd" class="form-control" @change="changeOrdersDates">
    </div>

    <div class="col-sm-1 mt-2">
    </div>


    <div class="col-auto mt-2">
      <input id="cbHidden" class="form-check-input align-middle me-1" type="checkbox" v-model="bHidden" @change="saveOptions">
      <label for="cbHidden" class="col-form-label align-middle">Показать скрытые товары</label>
    </div>


    <div class="col-auto">
      <div class="input-group mt-2">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <universal-table
        :items="filteredRows"
        :allHeaders="allHeaders"
        localStorageHeadersName="OzonOwnProductList_headers"
        :arrTable="arrTable"
        :showTotalRow="true"
        tableClass="table-striped table-bordered border-secondary table-hover table-sm"
        ref="refUniversalTable"
      />
    </div>
  </div>

</template>

<script>
import {
  fetchDrf,
  showMessageResult,
} from "@/common";
import UniversalTable from "@/components/UniversalTable";

export default {
  name: "OzonOwnProductList",

  components: {
    UniversalTable
  },

  data() {
    return {
      arrTable: [],
      columns: [],
      allHeaders: [],

      wh: [],
      dates: [],

      dateStart: "",
      dateEnd: "",
      arrDates: [],

      filterString: "",
      bHidden: true,

      COL_ID: 'id',
      COL_FOTO: 'foto_link',
      COL_OZON_LK: 'ozon_lk',
      COL_OZON_LK_NAME: 'ozon_lk_name',
      COL_ARTICLE: 'article',
      COL_NAME: 'name',
      COL_COLOR: 'color',
      COL_BARCODE: 'barcode',
      COL_SIZE: 'size',

      COL_OZON_ID: 'ozon_id',
      COL_OZON_SKU: 'sku',

      COL_COST: 'cost',

      COL_QUANTITY_ORDERS_PERIOD: 'quantity_orders_period',
      COL_SUM_ORDERS_PERIOD: 'sum_orders_period',
      COL_QUANTITY_STOCKS: 'quantity_stocks',

      COL_HIDDEN: 'hidden',
      COL_PRICE_HINT: 'price_hint',
      COL_WH_STOCKS_HINT: 'wh_stocks_hint',
      COL_WH_ORDERS_PERIOD_HINT: 'wh_orders_period_hint',
      COL_WH: 'wh',

      COL_CHART_ORDERS: 'chart_orders',
      COL_CHART_ADV: 'chart_adv',
      COL_CHART_WH_STOCKS: 'chart_wh_stocks',
      COL_CHART_WH_ORDERS: 'chart_wh_orders',

      messageResult: "",
      gotError: false,
    }
  },


  async created() {
    document.title = "ОЗОН товары"
    this.allHeaders.push({id: this.COL_HIDDEN, name: "Скр", type: "checkbox", checkbox_path: "ozon-own-barcode", active: true})
    this.allHeaders.push({id: this.COL_ID, name: "ID"})
    this.allHeaders.push({id: this.COL_FOTO, name: "Фото", type: "foto", class: "position-sticky start-0 top-0 opacity-100 bg-white", active: true})
    this.allHeaders.push({id: this.COL_OZON_LK_NAME, name: "ЛК"})
    this.allHeaders.push({id: this.COL_ARTICLE, name: "Артикул", active: true})

    this.allHeaders.push({id: this.COL_OZON_ID, name: "Озон ID", active: true}) // , type: this.TYPE_COL_OZON_PRODUCT_ID
    this.allHeaders.push({id: this.COL_OZON_SKU, name: "SKU", active: true}) // , type: this.TYPE_COL_OZON_SKU

    this.allHeaders.push({id: this.COL_NAME, name: "Название", active: true, title: "name" })

    this.allHeaders.push({id: this.COL_QUANTITY_ORDERS_PERIOD, name: "Заказы за период", type: "order", totalRow: true, active: true, title: this.COL_WH_ORDERS_PERIOD_HINT})
    this.allHeaders.push({id: this.COL_SUM_ORDERS_PERIOD, name: "Сумма за период", type: "int", totalRow: true})
    this.allHeaders.push({id: this.COL_QUANTITY_STOCKS, name: "Всего на складах", type: "int", totalRow: true, active: true, title: this.COL_WH_STOCKS_HINT})

    this.initDates()
    await this.restoreOptions();
    await this.loadProducts();
  },

  computed: {
    filteredRows() {
      return this.arrTable.filter(row => {

        return true; // !!!!!!!!!!!

        const filterString = this.filterString.toString().toLowerCase().trim();

        const article = (row[this.COL_ARTICLE] || '').toString().toLowerCase();
        const ozonId = (row[this.COL_OZON_ID] || '').toString().toLowerCase();
        const ozonSku = (row[this.COL_OZON_SKU] || '').toString().toLowerCase();
        const name = (row[this.COL_NAME || '']).toString().toLowerCase();

        const hidden = row[this.COL_HIDDEN];

        let bTextFilterOk = false

        if ( article.includes(filterString) || ozonId.includes(filterString) || ozonSku.includes(filterString)|| name.includes(filterString)  ){
          bTextFilterOk = true
        }

        // let bHiddenFilterOk = false
        // if (this.bHidden && hidden) bHiddenFilterOk = true
        // if (!hidden) bHiddenFilterOk = true  // Не архивные показываем в любом случае

        let bHiddenFilterOk = true

        return bTextFilterOk && bHiddenFilterOk
      });
    }
  },

  methods: {
    initDates() {
      this.dateEnd = new Date().toISOString().slice(0, 10);

      let dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - 14)
      this.dateStart = dateStart.toISOString().slice(0, 10);
    },

    restoreOptions(){
    },

    saveOptions(){
    },

    prepareTable(){
    },

    async loadProducts(){
      let searchParams = new URLSearchParams({
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
      })

      let res = await fetchDrf(`/ozon-own-product?${searchParams}`)

      if (!res.gotError){
        this.arrTable = res.data;

        console.log("this.arrTable")
        console.log(this.arrTable)

        this.prepareTable()
      }
      else {
        showMessageResult(res, this);
      }
    },

  }
}
</script>

<style scoped>

</style>