<template>
  <MessageResultDiv :messageResult="messageResult" :gotError="gotError" />
  <Toast :toastIsShowed="toastIsShowed" :gotError="gotError" :toastMessage="toastMessage" />

  <div ref="divModalWindow" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">

          <div class="row">
            <div class="col-12">
              <span class="fw-bold">{{textModalHeader}}</span>
              <button type="button" class="btn-close float-end" aria-label="Close" @click="hideModalWindow"></button>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12" >
              <span v-html="textModalContent"></span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <h1>ВБ товары (баркоды)</h1>


  <div class="row">
    <div class="col-auto d-inline mt-2">
      <input v-model="dateStart" type="date" id="inputDateStart" class="form-control" @change="selectChange">
    </div>

    <div class="col-auto d-inline mt-2">
      <label for="inputDateEnd" class="col-form-label">по</label>
    </div>

    <div class="col-auto d-inline mt-2">
      <input v-model="dateEnd" type="date" id="inputDateEnd" class="form-control" @change="selectChange">
    </div>

    <div class="col-sm-1 mt-2">
    </div>

    <div class="col-auto mt-2">
      <input id="cbShowHiddenBarcodes" class="form-check-input align-middle me-1" type="checkbox" v-model="bShowHiddenBarcodes" @change="saveOptions">
      <label for="cbShowHiddenBarcodes" class="col-form-label align-middle">Показать скрытые товары</label>
    </div>


    <div class="col-auto">
      <div class="input-group mt-2">
        <input v-model="filterString" type="text" class="form-control" placeholder="Фильтр" aria-describedby="btnClearFilter">
        <button class="btn btn-outline-secondary" type="button" id="btnClearFilter" @click="filterString = ''">X</button>
      </div>
    </div>

  </div>


  <div class="row">
    <div class="col-auto mt-2">
      <UniversalTable
        :items="filteredRows"
        :allHeaders="allHeadersWbBarcodes"
        localStorageHeadersName="DashBoardBarcodes_headers"
        :arrTable="arrTableWbBarcodes"
        tableClass="table-striped  table-bordered border-secondary table-hover table-sm"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-auto mt-2">
      <p>Скройте ненужные товары в разделе <router-link :to="{ name: 'WbOwnProductList'}" target="_blank" class="fw-normal">"ВБ товары"</router-link>  </p>
    </div>
  </div>

</template>

<script>
import BarChartUniversal from '../components/BarChartUniversal.vue'
import UniversalTable from "@/components/UniversalTable";

import {Modal, Popover} from 'bootstrap'

import {
  fetchDrf,
  TYPE_COL_WB_ORDERS_BARCHART,

  TYPE_COL_CHART_WH_STOCKS,
  TYPE_COL_CHART_WH_ORDERS,

  prepareNumber,
  prepareDate,
} from "@/common";
// import {ref} from "vue";

export default {
  name: "DashBoard",

  components: {
    BarChartUniversal,
    UniversalTable,
  },

  data() {
    return {
      dateStart: "",
      dateEnd: "",

      toastIsShowed: false,
      toastDelay: 5000,
      toastMessage: "",

      filterString: "",

      bShowHiddenBarcodes: true,

      arrTableWbBarcodes: [],
      columnsBarcodes: [],
      allHeadersWbBarcodes: [],

      arrTableWbAdv: [],
      columnsAdv: [],
      allHeadersWbAdv: [],

      arrTableOzonProducts: [],
      columnsProducts: [],
      allHeadersOzonProducts: [],

      COL_NAME: 'name',

      COL_STOCK_QUANTITY: 'stock_quantity',
      COL_STOCK_QUANTITY_WAITINGDOCS: 'stock_quantity_waitingdocs',
      COL_STOCK_QUANTITY_EXPIRING: 'stock_quantity_expiring',
      COL_STOCK_QUANTITY_DEFECT: 'stock_quantity_defect',

      COL_WB_ORDERS_BARCHART: 'wb_orders_barchart',
      COL_ORDERS_QUANTITY: 'orders_quantity_total',
      COL_ORDERS_SUM: 'orders_sum_total',
      COL_ADV_COST: 'adv_cost',

      COL_HIDDEN: 'hidden',

      COL_FOTO: 'foto_link',
      COL_ARTICLE_WB: 'nm_id',
      COL_ARTICLE: 'article',
      COL_SKU: 'sku',

      COL_COLOR: 'color',
      COL_BARCODE: 'barcode',
      COL_SIZE: 'size',
      COL_COST: 'cost',

      COL_FINAL_PRICE: 'final_price',
      COL_PRICE: 'price',
      COL_DISCOUNT: 'discount',

      COL_WB_ID: 'wb_id',
      COL_NM_IDS: 'nm_ids',
      COL_ADV_TYPE: 'adv_type',
      COL_ADV_TYPE_NAME: 'adv_type_name',
      COL_STATUS: 'status',
      COL_STATUS_NAME: 'status_name',
      COL_BET_CURRENT: 'bet_current',

      COL_WB_STOCK_DETAILS: "wb_stock_details",
      COL_WB_ORDER_DETAILS: "wb_order_details",

      COL_CHART_WH_STOCKS: 'chart_wh_stocks', // !!!!
      COL_CHART_WH_ORDERS: 'chart_wh_orders',

      TYPE_COL_WB_ORDERS_BARCHART: "",

      TYPE_COL_ADV_STATS_BARCHART: "",

      selectedWh: 0,

      arrWbBarcodes: [],

      divModalWindow: undefined,
      textModalHeader: "",
      textModalContent: "",

      messageResult: "",
      gotError: false,
    }
  },

  async created() {
    document.title = "ВБ товары"

    this.TYPE_COL_WB_ORDERS_BARCHART = TYPE_COL_WB_ORDERS_BARCHART

    this.TYPE_COL_CHART_WH_STOCKS = TYPE_COL_CHART_WH_STOCKS
    this.TYPE_COL_CHART_WH_ORDERS = TYPE_COL_CHART_WH_ORDERS

    this.allHeadersWbBarcodes.push({id: this.COL_HIDDEN, name: "Скрытый", type: "checkbox", checkbox_path: "wb-own-barcode", active: true})
    this.allHeadersWbBarcodes.push({id: this.COL_FOTO, name: "Фото", active: true, title: "name", type: "foto", class: "position-sticky start-0 top-0 opacity-100 bg-white", })
    this.allHeadersWbBarcodes.push({id: this.COL_ARTICLE, name: "Артикул", active: true })
    this.allHeadersWbBarcodes.push({id: this.COL_ARTICLE_WB, name: "Артикул ВБ", active: true })
    this.allHeadersWbBarcodes.push({id: this.COL_NAME, name: "Название", active: true })
    this.allHeadersWbBarcodes.push({id: this.COL_WB_ORDERS_BARCHART, name: "График заказов", active: true, type: this.TYPE_COL_WB_ORDERS_BARCHART })
    this.allHeadersWbBarcodes.push({id: this.COL_STOCK_QUANTITY, name: "Остаток", active: true, type: "int", totalRow: true, modal_window_content: this.COL_WB_STOCK_DETAILS })
    this.allHeadersWbBarcodes.push({id: this.COL_ORDERS_QUANTITY, name: "Заказы за период", active: true, type: "int", totalRow: true, modal_window_content: this.COL_WB_ORDER_DETAILS })

    this.allHeadersWbBarcodes.push({id: this.COL_ORDERS_SUM, name: "Сумма заказов", active: false, type: "int", totalRow: true })

    this.allHeadersWbBarcodes.push({id: this.COL_CHART_WH_STOCKS, name: "Остатки <br/>по складам", type: this.TYPE_COL_CHART_WH_STOCKS, active: true})
    this.allHeadersWbBarcodes.push({id: this.COL_CHART_WH_ORDERS, name: "Заказы <br/>по складам", type: this.TYPE_COL_CHART_WH_ORDERS, active: true})

    this.allHeadersWbBarcodes.push({id: this.COL_SIZE, name: "Размер", active: true})
    this.allHeadersWbBarcodes.push({id: this.COL_COLOR, name: "Цвет", active: true})
    this.allHeadersWbBarcodes.push({id: this.COL_BARCODE, name: "Баркод", active: true})
    this.allHeadersWbBarcodes.push({id: this.COL_COST, name: "Себестоимость", type: "int", active: true})

    this.allHeadersWbBarcodes.push({id: this.COL_FINAL_PRICE, name: "Цена", type: "int", title: this.COL_PRICE_HINT, active: true})
    this.allHeadersWbBarcodes.push({id: this.COL_PRICE, name: "Цена до скидки", type: "int", active: true})
    this.allHeadersWbBarcodes.push({id: this.COL_DISCOUNT, name: "Скидка, %", type: "int", active: true})

    this.initDates()
    this.loadProductsData()

    await this.restoreOptions();

  },

  computed:{

    filteredRows() {
      return this.arrTableWbBarcodes.filter(row => {

        const filterString = this.filterString.toString().toLowerCase().trim();

        const whName = (row[this.COL_WH_NAME] || '').toString().toLowerCase();
        const hidden = row[this.COL_HIDDEN];

        let bTextFilterOk = false
        if ( whName.includes(filterString) ){
          bTextFilterOk = true
        }

        let bHiddenFilterOk = true
        if (!this.bShowHiddenBarcodes && hidden) bHiddenFilterOk = false

        return bTextFilterOk && bHiddenFilterOk
      });
    },

  },

  methods: {

    restoreOptions(){
      if(localStorage.getItem("WbOwnProductList_bShowHiddenBarcodes"))  this.bShowHiddenBarcodes = JSON.parse(localStorage.getItem("WbOwnProductList_bShowHiddenBarcodes"))
      if(localStorage.getItem("WbOwnProductList_bShowWithStocksAndOrders"))  this.bShowWithStocksAndOrders = JSON.parse(localStorage.getItem("WbOwnProductList_bShowWithStocksAndOrders"))
    },

    saveOptions(){
      localStorage.setItem("WbOwnProductList_bShowHiddenBarcodes", JSON.stringify(this.bShowHiddenBarcodes));
      localStorage.setItem("WbOwnProductList_bShowWithStocksAndOrders", JSON.stringify(this.bShowWithStocksAndOrders));
    },

    showModalWindow(){
      this.divModalWindow = new Modal(this.$refs.divModalWindow)
      this.divModalWindow.show()
    },

    hideModalWindow(){
      this.divModalWindow.hide()
    },

    showModalWindowClick(item, header){
      this.textModalHeader = header["name"]
      this.textModalContent = item[header.modal_window_content]

      this.showModalWindow()
    },

    onClickWbBarchartBarcodes(index, item){
      if (index === undefined) return;

      let orders_wh = this.arrWbTotal[index]["orders_wh"]

      if (!orders_wh) return

      let sOrdersWh = "" ;
      let totalSum = 0;
      let totalSumSpp = 0;
      let totalQuantity = 0;
      let date = this.arrWbTotal[index]["date"]

      for (let key in orders_wh) {
        let quantity = orders_wh[key]["quantity"]
        let sum = orders_wh[key]["sum"]
        let sumSpp = orders_wh[key]["sum_spp"]
        let wh_name = orders_wh[key]["wh_name"]

        let wh_color = orders_wh[key]["wh_color"]
        if (!wh_color) wh_color = 'grey'

        totalSum += sum
        totalSumSpp += sumSpp
        totalQuantity += quantity

        sOrdersWh += `<span class="badge" style="background-color: ${wh_color}">&nbsp;</span> ${wh_name}: <b>${sum}</b> (${quantity}) <br/>`
      }

      let avgSum = 0
      let avgSumSpp = 0
      let avgSppDiscountPercent = 0

      if (totalQuantity) {
        avgSum = Math.floor(totalSum / totalQuantity)
        avgSumSpp = Math.floor(totalSumSpp / totalQuantity)
      }
      if (totalSum) avgSppDiscountPercent = Math.floor((1 - (totalSumSpp/totalSum)) * 100)

      if (!avgSumSpp) avgSppDiscountPercent = undefined

      sOrdersWh += `<hr>`
      sOrdersWh += `Всего заказов: <b>${prepareNumber(totalQuantity)}</b> <br/>`
      sOrdersWh += `Сумма: <b>${prepareNumber(totalSum)}</b> <br/>`

      sOrdersWh += `Средняя цена: <b>${prepareNumber(avgSum)}</b> <br/>`
      sOrdersWh += `Средняя цена с СПП: <b>${prepareNumber(avgSumSpp)}</b> <br/>`
      sOrdersWh += `Средняя СПП: <b>${prepareNumber(avgSppDiscountPercent)}%</b> <br/>`

      this.textModalHeader = `Заказы за ${prepareDate(date)}`
      this.textModalContent = sOrdersWh
      this.showModalWindow()
    },

    onClickOzonBarchartProducts(index, item){
      if (index === undefined) return;

      let date = this.arrOzonTotal[index]["date"]
      let totalSum = this.arrOzonTotal[index]["sum"]
      let totalQuantity = this.arrOzonTotal[index]["quantity"]

      let sOrdersWh = "" ;
      let avgSum = 0

      if (totalQuantity) {
        avgSum = Math.floor(totalSum / totalQuantity)
      }

      sOrdersWh += `<hr>`
      sOrdersWh += `Всего заказов: <b>${prepareNumber(totalQuantity)}</b> <br/>`
      sOrdersWh += `Сумма: <b>${prepareNumber(totalSum)}</b> <br/>`
      sOrdersWh += `Средняя цена: <b>${prepareNumber(avgSum)}</b> <br/>`

      this.textModalHeader = `Заказы за ${prepareDate(date)}`
      this.textModalContent = sOrdersWh
      this.showModalWindow()
    },

    async prepareTable(){
      for (let i = 0; i < this.arrTableWbBarcodes.length; i++){
        // stocks wb
        this.arrTableWbBarcodes[i][this.COL_WB_STOCK_DETAILS] = "";

        let sWbStockDetails = ""
        let wb_stocks_wh = this.arrTableWbBarcodes[i]["stocks_wh"]

        if (!wb_stocks_wh) continue

        for (let j = 0; j < wb_stocks_wh.length; j++){
          let color = wb_stocks_wh[j]["color"]
          if (!color) color = "grey"
          sWbStockDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${wb_stocks_wh[j]["name"]} : <b>${wb_stocks_wh[j]["quantity"]}</b> <br/>`
        }

        this.arrTableWbBarcodes[i][this.COL_WB_STOCK_DETAILS] = sWbStockDetails;

        // orders wb
        let sWbOrderDetails = ""

        let wb_orders_wh = this.arrTableWbBarcodes[i]["orders_wh"]

        if (!wb_orders_wh) wb_orders_wh = []

        for (let j = 0; j < wb_orders_wh.length; j++){
          let color = wb_orders_wh[j]["color"]
          if (!color) color = "grey"
          sWbOrderDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${wb_orders_wh[j]["name"]} : <b>${wb_orders_wh[j]["quantity"]}</b> <br/>`
        }

        sWbOrderDetails += `<hr/>`
        sWbOrderDetails += `Всего заказов: <b>${prepareNumber(this.arrTableWbBarcodes[i][this.COL_ORDERS_QUANTITY])}</b>  <br/>`
        sWbOrderDetails += `Сумма: <b>${prepareNumber(this.arrTableWbBarcodes[i][this.COL_ORDERS_SUM])}</b>  <br/>`
        sWbOrderDetails += `Затраты на рекламу: <b>${prepareNumber(this.arrTableWbBarcodes[i][this.COL_ADV_COST])}</b> <br/>`

        this.arrTableWbBarcodes[i][this.COL_WB_ORDER_DETAILS] = sWbOrderDetails;
      }

      for (let i = 0; i < this.arrTableOzonProducts.length; i++) {
        // stocks ozon
        this.arrTableOzonProducts[i][this.COL_OZON_STOCK_DETAILS] = "";

        let sOzonStockDetails = ""
        let ozon_stocks_wh = this.arrTableOzonProducts[i]["stocks_wh"]

        if (!ozon_stocks_wh) continue

        for (let j = 0; j < ozon_stocks_wh.length; j++) {
          let color = ozon_stocks_wh[j]["color"]
          if (!color) color = "grey"
          sOzonStockDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${ozon_stocks_wh[j]["name"]} : <b>${ozon_stocks_wh[j]["quantity"]}</b> <br/>`
        }

        sOzonStockDetails += `<hr/>`
        sOzonStockDetails += `Остаток, доступный для продажи: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY])}</b>  <br/>`
        sOzonStockDetails += `<span style="color: grey">Остаток, который ждет документы: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY_WAITINGDOCS], '-')}</b></span> <br/>`
        sOzonStockDetails += `<span style="color: grey">Остаток, где истекает срок годности: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY_EXPIRING], '-')}</b></span>  <br/>`
        sOzonStockDetails += `<span style="color: grey">Остаток с дефектами: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_STOCK_QUANTITY_DEFECT], '-')}</b></span>  <br/>`

        this.arrTableOzonProducts[i][this.COL_OZON_STOCK_DETAILS] = sOzonStockDetails;

        // orders ozon
        let sOzonOrderDetails = ""

        let ozon_orders_wh = this.arrTableOzonProducts[i]["orders_wh"]

        if (!ozon_orders_wh) ozon_orders_wh = []

        for (let j = 0; j < ozon_orders_wh.length; j++){
          let color = ozon_orders_wh[j]["color"]
          if (!color) color = "grey"
          sOzonOrderDetails += `<span class="badge" style="background-color: ${color}">&nbsp;</span> ${ozon_orders_wh[j]["name"]} : <b>${ozon_orders_wh[j]["quantity"]}</b> <br/>`
        }

        let avgPrice = 0
        if (this.arrTableOzonProducts[i][this.COL_ORDERS_QUANTITY]){
          avgPrice = this.arrTableOzonProducts[i][this.COL_ORDERS_SUM] / this.arrTableOzonProducts[i][this.COL_ORDERS_QUANTITY]
        }

        sOzonOrderDetails += `<hr/>`
        sOzonOrderDetails += `Всего заказов: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_ORDERS_QUANTITY])}</b>  <br/>`
        sOzonOrderDetails += `Сумма: <b>${prepareNumber(this.arrTableOzonProducts[i][this.COL_ORDERS_SUM])}</b>  <br/>`
        sOzonOrderDetails += `Средняя цена: <b>${prepareNumber(avgPrice)}</b>  <br/>`

        this.arrTableOzonProducts[i][this.COL_OZON_ORDER_DETAILS] = sOzonOrderDetails;
      }
    },

    async selectChange(){
      await this.loadProductsData()
    },

    initDates() {
      this.dateEnd = new Date().toISOString().slice(0, 10);

      let dateStart = new Date();
      dateStart.setDate(dateStart.getDate() - 14)
      this.dateStart = dateStart.toISOString().slice(0, 10);
    },

    async loadProductsData() {

      let searchParams = new URLSearchParams({
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        wh: this.selectedWh,
      })

      // let res = await fetchDrf(`/dashboard/?${searchParams}`)
      let res = await fetchDrf(`/wb-own-barcode?${searchParams}`)

      console.log("res")
      console.log(res)

      if (!res.gotError) {
        this.arrTableWbBarcodes = res.data[0].wb_barcodes;

        this.prepareTable()
      }
      else {
        this.messageResult = res.messageResult;
        this.gotError = res.gotError;
      }
    },
  },
}
</script>

<style scoped>
  .popover {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 10px;
  }
  .popover-header {
    font-weight: bold;
  }
  .popover-body {
    color: #333;
  }
</style>